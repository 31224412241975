@use "../settings/color.scss"as color;


.c-card {
    background: color.$white;
    border-radius: 4px;
    border: 1px solid color.$border;
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
    color: color.$color-text;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    &__header {
        padding: 7px 12px;
        background: color.$border;
        font-weight: 700;
    }

    &__body {
        padding: 7px 12px 20px 12px;

    }

    &__list {
        list-style: disc;
        padding-left: 14px;

        &-item {
            list-style: disc;

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }

    &__content {
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    &__label {
        position: relative;
        display: inline-block;
        color: color.$color-text;
        width: 80px;
        font-weight: 500;
        margin-right: 8px;

        &::after {
            content: ':';
            color: color.$color-text;
            position: absolute;
            right: 0;
        }
    }
}