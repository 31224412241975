@use "../settings/color.scss"as color;

@layer app.components {
    .c-content {
        flex: 1;
        min-height: 0;
        padding: 1.2rem;
        overflow: auto;


        &::-webkit-scrollbar {
            width: 0.8rem;
            background-color: #eeeeee;
        }

        &::-webkit-scrollbar-thumb {
            background: #8a94a3;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-corner {
            background: #000;
        }

        .contentCenter{
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

    .c-tile {
        display: flex;
        flex-wrap: wrap;
    }

    .c-card {
        $self: &;
        width: 24.6rem;
        height: 21.8rem;
        padding: 1.2rem;
        border-radius: 0.4rem;
        transition: all 250ms linear;
        cursor: pointer;
        color: color.$color-text;

        &:hover {
            background-color: color.$background-selected;

            #{$self}__top-play {
                opacity: 1;
            }
        }

        &__top {
            position: relative;
            display: block;
            border-radius: 0.3rem;
            border: 0.1rem solid color.$border;
            margin-bottom: 0.8rem;
            height:112px;

            #{$self}__top-image {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            #{$self}__top-play {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background: rgba(0, 19, 28, 0.4);
                transition: all 250ms linear;

                &::before {
                    content: "";
                    position: absolute;
                    left: calc(50% - 48px / 2 - 0.5px);
                    top: calc(50% - 48px / 2);
                    display: block;
                    width: 4.8rem;
                    height: 4.8rem;
                    background: #ffffff;
                    border: 2px solid #bae0de;
                    box-shadow: 0px 4px 12px rgba(13, 22, 29, 0.24);
                    border-radius: 4.8rem;
                    z-index: 0;
                }

                &-image {
                    display: block;
                    width: 2.4rem;
                    height: 2.4rem;
                    position: relative;
                    z-index: 1;
                }
            }

            #{$self}__top-new {
                display: block;
                position: absolute;
                left: 0.4rem;
                top: 0.4rem;
                background: #b23838;
                box-shadow: 0 0.4rem 1.2rem rgba(13, 22, 29, 0.24);
                border-radius: 1.2rem;
                font-size: 1.1rem;
                line-height: 1.7rem;
                text-align: center;
                color: #ffffff;
                padding: 0 0.8rem;
            }
        }

        &__category {
            display: block;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            margin-bottom: 0.2rem;
            color: color.$color-text-gray;
        }

        &__title {
            display: block;
            font-weight: 500;
            font-size: 11px;
            line-height: 17px;
            margin-bottom: 0.4rem;
        }

        &__date {
            display: block;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            color: color.$color-text-gray;
        }
    }
}