@use '../settings/color.scss' as color;

html {
    font-size: 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

body {
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 1.1rem;
    line-height: 1.7rem;
    font-family: 'Roboto', sans-serif;
    color: color.$color-text;

    width: 100%;
    height: 100%;
    overflow: hidden;
}

#root {
    max-width: 102rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

