@use "../settings/color.scss" as color;
@layer app.components {
    .c-video-section {
        $self: &;
        display: flex;
        height: 100%;

        &__left {
            flex: 1;
            min-width: 0;
            display: flex;
            flex-direction: column;

            &-header {
                border-bottom: 1px solid color.$border;
                height: 3.5rem;
                padding: 0.6rem 1.2rem;
                display: flex;
                align-items: center;
            }

            &-link {
                display: flex;
                align-items: center;
                column-gap: 0.4rem;
                font-weight: 400;
                font-size: 11px;
                line-height: 17px;
                color: color.$color-text-blue;
                cursor: pointer;
            }

            &-content {
                padding: 1.2rem;
                overflow-x: hidden;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 0.8rem;
                    background-color: #eeeeee;
                }

                &::-webkit-scrollbar-thumb {
                    background: #8a94a3;
                    border-radius: 4px;
                }

                &::-webkit-scrollbar-corner {
                    background: #000;
                }
            }
        }

        &__right {
            width: 29.2rem;
            background-color: color.$background-gray;
            border-left: 1px solid color.$border;
            display: flex;
            flex-direction: column;

            &-header {
                border-bottom: 1px solid color.$border;
                height: 3.5rem;
                padding: 0.6rem 1.2rem;
                display: flex;
                align-items: center;
            }

            &-content {
                flex: 1;
                min-height: 0;
                overflow-x: hidden;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 0.8rem;
                    background-color: #eeeeee;
                }

                &::-webkit-scrollbar-thumb {
                    background: #8a94a3;
                    border-radius: 4px;
                }

                &::-webkit-scrollbar-corner {
                    background: #000;
                }
            }
        }
    }

    .c-card-horizontal {
        $self: &;
        display: flex;
        align-items: center;
        column-gap: 0.8rem;
        padding: 1.2rem;
        transition: all 250ms linear;
        cursor: pointer;
        color: color.$color-text;

        &:hover {
            background-color: color.$background-selected;

            #{$self}__left-play {
                opacity: 1;
            }
        }

        &.is-active {
            background-color: color.$background-selected;

            #{$self}__left-active {
                opacity: 1;
            }
        }

        &__left {
            border: 1px solid color.$border;
            border-radius: 0.4rem;
            position: relative;

            &-image {
                display: block;
                width: 10.8rem;
                height: auto;
            }

            #{$self}__left-play {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background: rgba(0, 19, 28, 0.4);
                transition: all 250ms linear;
                &::before {
                    content: "";
                    position: absolute;
                    left: calc(50% - 24px / 2 - 0.5px);
                    top: calc(50% - 21px / 2);
                    display: block;
                    width: 2.4rem;
                    height: 2.1rem;
                    background: #ffffff;
                    border: 1px solid #bae0de;
                    box-shadow: 0px 4px 12px rgba(13, 22, 29, 0.24);
                    border-radius: 4.8rem;
                    z-index: 0;
                }

                &-image {
                    display: block;
                    width: 1.2rem;
                    height: 1.2rem;
                    position: relative;
                    z-index: 1;
                }
            }

            #{$self}__left-active {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background: rgba(0, 19, 28, 0.4);
                transition: all 250ms linear;
                

                &-image {
                    display: block;
                    width: 1.2rem;
                    height: 1.2rem;
                    position: relative;
                    z-index: 1;
                }
            }
        }

        &__right {
            flex: 1;
            min-width: 0;
        }

        &__category {
            display: block;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            margin-bottom: 0.3rem;
            color: color.$color-text-gray;
        }

        &__title {
            display: block;
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;
            margin-bottom: 0.3rem;
        }
    }

    .c-video {
        width: 100%;
        margin-bottom: 2rem;
    }

    .c-category-wrapper {
        display: flex;
        margin-bottom: 1.2rem;
        align-items: center;
    }

    .c-category {
        background: #f3f5f7;
        padding: 0 0.8rem;
        border: 1px solid #d4d8de;
        border-radius: 12px;
        font-weight: 400;
        font-size: 11px;
        line-height: 17px;
        text-align: center;
        margin-right: 10px;
    }

    .c-title {
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        margin-bottom: 0.4rem;
    }

    .c-text {
        font-weight: 400;
        font-size: 11px;
        line-height: 17px;
        color: color.$color-text-gray;
    }
}
