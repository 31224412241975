$color-text: #203140;
$color-text-gray: #8A94A3;
$color-text-blue: #147B8D;

$background-white: #FFF;
$background-gray: #F9FBFC;
$background-selected: #E8F2F4;
$shadeBg: #F3F5F7;
$gray-light:#EBEDF0;
$black:#000000;
$white:#ffffff;
$border: #D4D8DE;
$border-selected:#BAE0DE;