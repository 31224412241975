@use "../settings/color.scss"as color;

@layer app.components {
    .c-header {
        height: 3.5rem;
        padding: 0.6rem 1.2rem;
        border-bottom: 1px solid color.$border;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__nav {
            display: flex;
        }
    }

    .c-tab-button {
        font-size: 13px;
        font-weight: 500;
        line-height: 19px;
        color: #147B8D;
        padding: 2px 12px;
        border-radius: 3px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        &--active {
            color: #203140;
            background: #BAE0DE;
        }
    }

    .c-search {
        width: 100%;
        height: 2.3rem;
        padding: 0 2rem 0 0.8rem;
        background: color.$background-white;
        border: 1px solid color.$border;
        border-radius: 0.3rem;
        background-repeat: no-repeat;
        background-position: center right 0.8rem;
        outline: 0;
        font-size: 11px;
        font-weight: 400;
        line-height: 17px;
        color: color.$color-text-gray;

        &::placeholder {
            color: color.$color-text-gray;
        }

        &--lg {
            max-width: 31.2rem;
        }
    }
}