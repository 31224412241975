@use "../settings/color.scss"as color;

.report {
    display: flex;
    gap: 1.3rem;
    height: 100%;
}

.actionPanel {
    border: 1px solid color.$border;
    height: 100%;
    border-radius: 4px;
    width: 300px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &__header {
        padding: 8px 12px;
        background: color.$shadeBg;
        border-bottom: 1px solid color.$border;
    }

    &__title {
        font-size: 11px;
        font-weight: 700;
        line-height: 17px;
        color: color.$black;
        margin-bottom: 8px;
    }

    &__list {
        height: 100%;
    }


    &__scrollBox {
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background: color.$gray-light;
        }

        &::-webkit-scrollbar-thumb {
            background: #8A94A3;
            border-radius: 4px;
        }

    }

    &__listItem {
        font-size: 11px;
        font-weight: 500;
        line-height: 17px;
        color: color.$color-text;
        padding: 12px;
        border-bottom: 1px solid transparent;
        transition: all 0.2s linear;
        cursor: pointer;

        &--active {
            background: color.$background-selected;
            border-color: color.$border-selected;
        }

        &:hover {
            background: color.$background-selected;
        }
    }
}

.contentPanel {
    border: 1px solid color.$border;
    padding: 14px 12px;
    height: 100%;
    border-radius: 4px;
    flex: 1;
    overflow-y: auto;

    &__header {
        margin-bottom: 20px;
    }

    &__title {
        font-size: 13px;
        font-weight: 500;
        line-height: 19px;
        color: color.$black;
        margin-bottom: 6px;
    }

    &__text {
        font-size: 11px;
        font-weight: 400;
        line-height: 17px;
        color: color.$color-text;
    }
}