@use "../settings/color.scss"as color;


.c-list {
    $self: &;
    list-style: disc !important;
    padding-left: 14px;
    margin-bottom: 16px;

    &__item {
        list-style: disc !important;
        margin-bottom: 8px;
    }

    &--decimal {
        list-style: decimal !important;
        padding-left: 14px;
        margin-bottom: 16px;

        #{$self}__item {
            list-style: decimal !important;
            margin-bottom: 8px;

        }
    }

    &--sub {
        font-weight: 500;
        margin: 16px 0;
    }
}